<template>
	<div class="adminSpace">
		<div class="admin-container" v-if="!isHomePageInfo">
			<div class="content">
				<div class="content-head">
					<div class="btn-left">
						<span @click="changeShow(1)" :class="isZH?'active':''">个人账号</span>
						<span @click="changeShow(2)" :class="!isZH?'active active2':''">公司账号</span>
					</div>
					<div class="btn-right">
						<el-input style="margin-right: 10px;" v-model="searchValue" placeholder="请输入内容"></el-input>
						<el-button type="primary" icon="el-icon-search" @click="search">搜索</el-button>
						<el-button type="primary" @click="driveExcel">导出</el-button>
						<el-button v-if="isZH" @click="isRegister = true">添加账号</el-button>
						<el-button v-else @click="isCompany = true">添加企业</el-button>
						<el-button @click="fixHomePage">主页管理</el-button>
						<!-- <el-button type="primary" @click="back" icon="iconfont icon-fanhui">返回</el-button> -->
					</div>
				</div>
				<div class="content-body">
					<div v-show="isZH">
						<el-table id="personalTable" :data="personalData" stripe height="675px" style="width: 100%">
							<el-table-column prop="userName" label="用户名">
							</el-table-column>
							<el-table-column prop="nickName" label="昵称">
							</el-table-column>
							<el-table-column>
								<template slot="header" slot-scope="scope">
									<p @click="sort(4)"><span>所属公司</span><span class="el-icon-sort margin-left"></span></p>
								</template>
								<template slot-scope="scope">
									<span v-if="scope.row.ownSpaceName == '个人'">个人</span>
									<span style="color: #409eff;" v-else>{{scope.row.ownSpaceName}}</span>
								</template>
							</el-table-column>
							<el-table-column>
								<template slot="header" slot-scope="scope">
									<p @click="sort(1)"><span>注册时间</span><span class="el-icon-sort margin-left"></span></p>
								</template>
								<template slot-scope="scope">
									<span>{{scope.row.registerTime | formatDate}}</span>
								</template>
							</el-table-column>
							<el-table-column>
								<template slot="header" slot-scope="scope">
									<p @click="sort(2)"><span>截至有效期</span><span class="el-icon-sort margin-left"></span></p>
								</template>
								<template slot-scope="scope">
									<span>{{scope.row.expireTime | formatDate}}</span>
								</template>
							</el-table-column>
							<el-table-column>
								<template slot="header" slot-scope="scope">
									<p @click="sort(3)"><span>剩余有效期</span><span class="el-icon-sort margin-left"></span></p>
								</template>
								<template slot-scope="scope">
									<span v-if="scope.row.lastDays >= 0">{{scope.row.lastDays}}天</span>
									<span v-else style="color: red;">已到期</span>
								</template>
							</el-table-column>
							<el-table-column>
								<template slot="header" slot-scope="scope">
									<p @click="sort(5)"><span>状态</span><span class="el-icon-sort margin-left"></span></p>
								</template>
								<template slot-scope="scope">
									<span style="color:#ff0000;" v-if="scope.row.statu<0">禁用</span>
									<span style="color:#36a24f;" v-else>正常</span>
								</template>
							</el-table-column>
							<el-table-column>
								<template slot="header" slot-scope="scope">
									<p @click="sort(6)"><span>空间大小</span><span class="el-icon-sort margin-left"></span></p>
								</template>
								<template slot-scope="scope">
									<span>{{scope.row.sizeStr}}</span>
								</template>
							</el-table-column>
							<el-table-column>
								<template slot="header" slot-scope="scope">
									<p @click="sort(7)"><span>最近登录时间</span><span class="el-icon-sort margin-left"></span></p>
								</template>
								<template slot-scope="scope">
									<span v-if="scope.row.loginTime">{{scope.row.loginTime | formatDate}}</span>
									<span v-else>从未登录</span>
								</template>
							</el-table-column>
							<el-table-column label="操作">
								<template slot-scope="scope">
									<div class="handle-box">
										<i class="el-icon-setting" @click="setting(scope.row)"></i>
										<!-- <i class="el-icon-delete" @click="deleteSpace(scope.row)"></i>
										<i class="el-icon-upload2" @click="addImg(scope.row)"></i>
										<i class="el-icon-finished" @click="initSpa(scope.row)"></i> -->
									</div>
								</template>
							</el-table-column>
						</el-table>
						<pagination :pagesize=20 :options="optionPersonal" ref="paginationPersonal" @getPagingResult="getPagingResultPersonal"></pagination>
					</div>
					<!-- 团队的table -->
					<div v-show="!isZH">
						<el-table id="teamTable" :data="teamData" stripe height="675px" style="width: 100%">
							<el-table-column prop="spaceName" label="公司账号">
							</el-table-column>
							<el-table-column prop="ownUserName" label="拥有者">
							</el-table-column>
							<el-table-column prop="numberAccount" label="公司成员">
								<template slot-scope="scope">
									<span>{{scope.row.numberAccountUsed}}</span>/<span>{{scope.row.numberAccount}}</span>
								</template>
							</el-table-column>
							<el-table-column>
								<template slot="header" slot-scope="scope">
									<p @click="sort(1)"><span>注册时间</span><span class="el-icon-sort margin-left"></span></p>
								</template>
								<template slot-scope="scope">
									<span>{{scope.row.registerTime | formatDate}}</span>
								</template>
							</el-table-column>
							<el-table-column>
								<template slot="header" slot-scope="scope">
									<p @click="sort(2)"><span>截至有效期</span><span class="el-icon-sort margin-left"></span></p>
								</template>
								<template slot-scope="scope">
									<span>{{scope.row.expireTime | formatDate}}</span>
								</template>
							</el-table-column>

							<el-table-column>
								<template slot="header" slot-scope="scope">
									<p @click="sort(3)"><span>剩余有效期</span><span class="el-icon-sort margin-left"></span></p>
								</template>
								<template slot-scope="scope">
									<span v-if="scope.row.lastDays > 0">{{scope.row.lastDays}}天</span>
									<span v-else style="color: red;">已到期</span>
								</template>
							</el-table-column>
							<el-table-column>
								<template slot="header" slot-scope="scope">
									<p @click="sort(6)"><span>空间大小</span><span class="el-icon-sort margin-left"></span></p>
								</template>
								<template slot-scope="scope">
									<span>{{scope.row.sizeStr}}</span>
								</template>
							</el-table-column>
							<el-table-column>
								<template slot="header" slot-scope="scope">
									<p @click="sort(5)"><span>状态</span><span class="el-icon-sort margin-left"></span></p>
								</template>
								<template slot-scope="scope">
									<span style="color:#ff0000;" v-if="scope.row.statu<0">禁用</span>
									<span style="color:#36a24f;" v-else>正常</span>
								</template>
							</el-table-column>
							<el-table-column label="操作">
								<template slot-scope="scope">
									<div class="handle-box">
										<i class="el-icon-setting" @click="setting(scope.row)"></i>
										<!-- <i class="el-icon-delete" @click="deleteSpace(scope.row)"></i>
										<i class="el-icon-upload2" @click="addImg(scope.row)"></i>
										<i class="el-icon-finished" @click="initSpa(scope.row)"></i> -->
									</div>
								</template>
							</el-table-column>
						</el-table>
						<pagination :pagesize=20 :options="optionTeam" ref="paginationTeam" @getPagingResult="getPagingResultTeam"></pagination>
					</div>
				</div>
			</div>
		</div>
		<admin-home-page-info v-if="isHomePageInfo"></admin-home-page-info>
		<el-dialog title="设置" :visible.sync="isSetting" width="30%">
			<div v-if="setContent == 1">
				<el-form :model="setData" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
					<el-form-item label="用户名" prop="userName">
						<el-input disabled v-model="setData.userName"></el-input>
					</el-form-item>
					<el-form-item label="到期时间" required>
						<p style="text-align: left;">
							<el-date-picker :picker-options="pickerOptions" v-model="setData.personExpireTime" type="date" :clearable="false" placeholder="选择日期">
							</el-date-picker>
						</p>
					</el-form-item>
					<el-form-item label="禁用" required>
						<p style="text-align: left;">
							<el-switch v-model="isSwitch" active-color="#475065" inactive-color="#dcdfe6" :active-value="true"
							 :inactive-value="false">
							</el-switch>
						</p>
					</el-form-item>
					<p style="text-align: right;">
						<el-button type="danger" @click="isSetting=false">取消</el-button>
						<el-button type="primary" @click="sureUpdate">确定</el-button>
					</p>
				</el-form>
			</div>
			<div v-else>
				<el-form :model="setData" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
					<el-form-item label="空间名字" prop="spaceName">
						<el-input disabled v-model="setData.spaceName"></el-input>
					</el-form-item>
					<el-form-item label="席位" prop="numberAccount">
						<el-input :min="maxNum" type="number" v-model="setData.numberAccount"></el-input>
					</el-form-item>
					<el-form-item label="到期时间" required>
						<p style="text-align: left;">
							<el-date-picker :clearable="false" :picker-options="pickerOptions" v-model="setData.expireTime" type="date" placeholder="选择日期">
							</el-date-picker>
						</p>
					</el-form-item>
					<el-form-item label="禁用" required>
						<p style="text-align: left;">
							<el-switch v-model="isSwitch" active-color="#475065" inactive-color="#dcdfe6">
							</el-switch>
						</p>
					</el-form-item>
					<p style="text-align: right;">
						<el-button type="danger" @click="isSetting=false">取消</el-button>
						<el-button type="primary" @click="sureUpdate">确定</el-button>
					</p>
				</el-form>
			</div>
		</el-dialog>
		<!-- 创建团队的dialog -->
		<!-- <div class="createTeam"> -->
		<el-dialog title="添加账号" :visible.sync="isRegister" width="30%">
			<el-form :model="registerUser" :rules="rulesregister" ref="registerForm" label-width="100px" class="demo-ruleForm">
				<el-form-item label="邮箱" prop="email">
					<!-- <span class="el-icon-message icon"></span> -->
					<el-input v-model="registerUser.email" placeholder="请输入邮箱" class="verifi"></el-input>
				</el-form-item>
				<el-form-item label="验证码" prop="verificationCode">
					<!-- <span class="el-icon-key icon"></span> -->
					<!-- <span class="icon2 icon3 icon4" v-else>{{countdown}} s</span> -->
					<div style="display: flex;">
						<div style="width: 200px;margin-right: 30px;">
							<el-input v-model="registerUser.verificationCode" placeholder="验证码"></el-input>
						</div>
						<el-button type="primary" class="icon2 icon3" v-if="countdown==0" @click="sendCode()">发送验证码</el-button>
						<el-button type="primary" class="icon2 icon3 icon4" v-else>{{countdown}} s</el-button>
					</div>
				</el-form-item>
				<el-form-item label="密码" prop="pwd">
					<el-input type="password" v-model="registerUser.pwd" placeholder="密码"></el-input>
				</el-form-item>
				<el-form-item label="确认密码" prop="surePassword">
					<el-input type="password" v-model="registerUser.surePassword" placeholder="确认密码"></el-input>
				</el-form-item>
				<p style="text-align: right;">
					<el-button type="danger" @click="isRegister = false">取消</el-button>
					<el-button type="primary" @click="register('registerForm')">确定</el-button>
				</p>
			</el-form>
		</el-dialog>
		<el-dialog title="添加企业" :visible.sync="isCompany" width="30%">
			<el-form ref="ruleFormS" :model="ruleFormSpace" :rules="rulesSpace" label-width="100px" label-position="left" style="text-align: left;">
				<el-form-item label="空间拥有者" prop="SpaceOwnerName">
					<el-input v-model="ruleFormSpace.SpaceOwnerName" placeholder="空间拥有者" />
				</el-form-item>
				<el-form-item label="企业名称" prop="Name">
					<el-input v-model="ruleFormSpace.Name" placeholder="企业名称" />
				</el-form-item>
				<el-form-item label="企业编码" prop="Descript">
					<el-input v-model="ruleFormSpace.Descript" placeholder="企业编码" />
				</el-form-item>
				<el-form-item label="空间大小" prop="SpaceSize">
					<el-input v-model="ruleFormSpace.SpaceSize" placeholder="空间大小"  :min="1" oninput="value=value.replace(/[^\d]/g,'')" />
				</el-form-item>

				<el-form-item label="账号个数" prop="NumberAccount">
					<el-input v-model.number="ruleFormSpace.NumberAccount" placeholder="账号个数" type="number" :min="1" oninput="value=value.replace(/[^\d]/g,'')" />
				</el-form-item>
				<el-form-item label="到期时间" prop="ExpireTime">
					<el-date-picker  v-model="ruleFormSpace.ExpireTime" type="datetime" format="yyyy-MM-dd HH:mm:ss" :clearable="false" value-format="yyyy-MM-dd HH:mm:ss"
					 placeholder="选择日期时间">
					</el-date-picker>
				</el-form-item>
				<el-form-item label="封面图:">
					<el-upload ref="uploadAdd" :show-file-list="false" action list-type="picture-card" :http-request="adduploadPicture"
					 :on-change="addgetPicture" accept=".bmp,.jpg,.png,.jpeg,.BMP,.JPG,.PNG,.JPEG">
						<img v-if="addPictureUrl" :src="addPictureUrl" class="avatarPic">
						<i v-else class="el-icon-plus avatar-uploader-icon"></i>
					</el-upload>
				</el-form-item>
				<p style="text-align: right;">
					<el-button type="danger" @click="isCompany = false">取消</el-button>
					<el-button type="primary" @click="addCompany">确定</el-button>
				</p>
			</el-form>
		</el-dialog>
		<!-- </div> -->
		<!-- 上传图片的dialog -->
		<el-dialog title="上传项目封面" :visible.sync="isAddImg" width="30%">
			<el-upload ref="imgAdd" :show-file-list="false" action list-type="picture-card" :http-request="addImgRequest"
			 :on-change="addimgPicture" accept=".bmp,.jpg,.png,.jpeg,.BMP,.JPG,.PNG,.JPEG">
				<img v-if="coverPictureStrImg" :src="coverPictureStrImg" class="avatarPic">
				<i v-else class="el-icon-plus avatar-uploader-icon"></i>
			</el-upload>
			<p style="text-align: right;">
				<el-button type="primary" @click="addImgSure">确定</el-button>
				<el-button type="primary" @click="isAddImg = false">取消</el-button>
			</p>
		</el-dialog>
	</div>
</template>

<script>
import TopBar from '@/components/layout/TopBar.vue'
import Pagination from '@/components/Pagination.vue'
import AdminHomePageInfo from './AdminHomePageInfo.vue'
import {
  mapState,
  mapMutations
} from 'vuex'
var addformData = new FormData()
var imgformData = new FormData()
export default {
  components: {
    TopBar,
    Pagination,
    AdminHomePageInfo
  },
  data () {
    return {
      isSwitch: false,
      isZH: true,
      searchValue: '',
      personalData: [],
      teamData: [],
      isSetting: false,
      setContent: 1, // 1代表个人，2代表企业
      setData: {},
      rules: {
        userName: [{
          required: true,
          message: '请输入用户名字',
          trigger: 'blur'
        }],
        spaceName: [{
          required: true,
          message: '请输入空间名字',
          trigger: 'blur'
        }, {
          validator: this.$utils.nameCheck,
          trigger: 'change'
        }],
        numberAccount: [{
          required: true,
          message: '请输入席位数量',
          trigger: 'blur'
        }]
      },
      pickerOptions: {
        disabledDate (time) {
          return time.getTime() < Date.now()
        }
      },
      isRegister: false,
      registerUser: {
        email: '',
        verificationCode: '',
        pwd: '',
        surePassword: ''
      },
      rulesregister: {
        email: [{
          required: true,
          message: '邮箱不能为空',
          trigger: 'blur'
        }],
        verificationCode: [{
          required: true,
          message: '验证码不能为空',
          trigger: 'blur'
        }],
        pwd: [{
          required: true,
          message: '密码不能为空',
          trigger: 'blur'
        }],
        surePassword: [{
          required: true,
          message: '请确认密码',
          trigger: 'blur'
        }]

      },
      countdown: 0,
      isCompany: false,
      ruleFormSpace: {
        Id: '',
        Name: '',
        Descript: '',
        SpaceSize: '无限制',
        NumberAccount: '',
        ExpireTime: ''
      },
      rulesSpace: {
        Name: [{
          required: true,
          message: '企业名不能为空',
          trigger: 'blur'
        }, {
          validator: this.$utils.nameCheck,
          trigger: 'change'
        }],
        Descript: [{
          required: true,
          message: '企业编码不能为空',
          trigger: 'blur'
        }],
        SpaceSize: [{
          required: true,
          message: '空间大小不能为空',
          trigger: 'blur'
        }],
        NumberAccount: [{
          required: true,
          message: '空间人数不能为空',
          trigger: 'blur'
        }, {
          type: 'number',
          message: '金额必须为数字值',
          trigger: 'blur'
        }],
        ExpireTime: [{
          required: true,
          message: '截止日期不能为空',
          trigger: 'blur'
        }],
        SpaceOwnerName: [{
          required: true,
          message: '空间拥有者不能为空',
          trigger: 'blue'
        }]
      },
      addPictureUrl: '',
      isAddImg: false,
      addImgData: {},
      coverPictureStrImg: '',
      optionPersonal: {
        param: {
          serachText: null
        },
        servicePage: false,
        serachField: ['userName', 'nickName'], // 需要搜索的字段数组
        url: '/api/space/getpersonspace'
      },
      optionTeam: {
        param: {
          serachText: null
        },
        servicePage: false,
        serachField: ['spaceName', 'ownUserName'], // 需要搜索的字段数组
        url: '/api/space/getteamspace'
      },
      maxNum: 1,
      isHomePageInfo: false
    }
  },
  mounted () {
    // this.initData()
  },
  filters: {
    formatDate (time) {
      let date = new Date(time) // 时间戳为10位需*1000，时间戳为13位的话不需乘1000
      let Y = date.getFullYear() + ''
      let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1)
      let D = (date.getDate() < 10 ? '0' + (date.getDate()) : date.getDate())
      let h = (date.getHours() < 10 ? '0' + (date.getHours()) : date.getHours())
      let m = (date.getMinutes() < 10 ? '0' + (date.getMinutes()) : date.getMinutes())
      let s = (date.getSeconds() < 10 ? '0' + (date.getSeconds()) : date.getSeconds())
      // if (i == 1) {
      // return Y + "-" + M + "-" + D
      // } else {
      return Y + '-' + M + '-' + D // + " " + h + ":" + m + ":" + s
      // }
    }
  },
  methods: {
    ...mapMutations('project', {
      initSpace: 'initSpace'
    }),
    ...mapMutations('spaceAction', {
      initspaceAction: 'initspaceAction'
    }),
    getPagingResultPersonal (res) {
      // console.log(res)
      this.personalData = res
    },
    getPagingResultTeam (res) {
      // console.log(res)
      this.teamData = res
    },
    changeShow (type) {
      if (type == 1) {
        this.isZH = true
      } else {
        this.isZH = false
      }
    },
    search () {
      if (this.isZH) {
        this.optionPersonal.param.serachText = this.searchValue
        this.$refs.paginationPersonal.serach(1)
      } else {
        this.optionTeam.param.serachText = this.searchValue
        this.$refs.paginationTeam.serach(1)
      }
    },
    sort (type) {
      if (this.isZH) {
        this.$refs.paginationPersonal.sort(type)
      } else {
        this.$refs.paginationTeam.sort(type)
      }
    },
    initData () {
      // this.$axios.get('/api/space/getpersonspace').then(res => {
      // 	console.log(res)
      // 	this.personalData = res
      // })
      // this.$axios.get('/api/space/getteamspace').then(res => {
      // 	console.log(res)
      // 	this.teamData = res
      // })
    },
    // 设置element ui 表单校验规定数字最小值
    setting (data) {
      this.setData = JSON.parse(JSON.stringify(data))
      if (data.spaceId) {
        this.maxNum = data.numberAccount
        // 是企业的时候
        this.setContent = 2
        // console.log(this.maxNum)
        // this.rules.numberAccount.push({
        // 	min: this.maxNum,
        // 	trigger: 'blur',
        // 	message: '请保证席位数量比原有席位多'
        // })
        console.log(this.rules)
      } else {
        // 是个人的时候
        this.setContent = 1
      }
      const that = this
      if (that.setData.personExpireTime) {
        var date = new Date(that.setData.personExpireTime)
      } else {
        var date = new Date(that.setData.expireTime)
      }
      this.pickerOptions = {
        disabledDate (time) {
          return time.getTime() < date.getTime()
        }
      }
      if (that.setData.statu < 0) {
        that.isSwitch = true
      } else {
        that.isSwitch = false
      }
      console.log(this.setData)
      this.isSetting = true
    },
    sureUpdate () {
      let data = {}
      console.log(this.isSwitch)
      console.log(this.setData.statu)
      let statu = 1
      if (!this.isSwitch) {
        if (this.setData.statu > 0) {
          statu = this.setData.statu
        } else {
          statu = 1
          this.setData.statu = 1
        }
      } else {
        if (this.setData.statu > 0) {
          statu = -1
          this.setData.statu = -1
        } else {
          statu = this.setData.statu
        }
      }
      if (this.setContent == 1) {
        // 是个人的时候
        data = {
          id: this.setData.personSpaceId,
          numberAccount: 1,
          expireTime: this.setData.personExpireTime,
          statu: statu,
          spaceSize: 0
        }
      } else {
        data = {
          id: this.setData.spaceId,
          numberAccount: this.setData.numberAccount,
          expireTime: new Date(this.setData.expireTime),
          statu: statu,
          spaceSize: 0
        }
      }
      data.expireTime = new Date(data.expireTime)
      console.log(data)
      // return
      this.$axios.post('/api/space/adminupdatespace', data).then(res => {
        // console.log(res)
        if (res == '修改成功') {
          if (this.setContent == 1) {
            this.$refs.paginationPersonal.frontPaging()
            this.isSetting = false
          } else {
            console.log('修改团队')
            this.$refs.paginationTeam.frontPaging()
            this.isSetting = false
          }
          this.$message({
            showClose: true,
            message: '修改成功',
            type: 'success'
          })
        } else {
          this.$message({
            showClose: true,
            message: res,
            type: 'error'
          })
        }
      })
    },
    timeToString (timeObj) {
      console.log(timeObj)
      timeObj = new Date(timeObj)
      var str = ''
      var year = timeObj.getFullYear()
      var month = timeObj.getMonth() + 1
      var date = timeObj.getDate()
      var time = timeObj.toTimeString().split(' ')[0]
      var rex = new RegExp(/:/g)
      str = year + '-' + month + '-' + date // + " " + "00:00:00";//time.replace(rex, ":");
      // console.log("------当前日期："+str);
      return str
    },
    deleteSpace (data) {
      this.$confirm('此操作将永久删除该空间, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let id = 0
        if (data.userId) {
          id = data.personSpaceId
        } else {
          id = data.spaceId
        }
        this.$axios.get(`/api/space/deletespacebyid?spaceId=${id}`).then(res => {
          console.log(res)
          if (res == '删除成功') {
            this.$message({
              showClose: true,
              message: '删除成功',
              type: 'success'
            })
            if (data.userId) {
              let index = this.personalData.findIndex(item => {
                return item.userId == data.userId
              })
              this.personalData.splice(index, 1)
            } else {
              let index = this.teamData.findIndex(item => {
                return item.spaceId == data.spaceId
              })
              this.teamData.splice(index, 1)
            }
          } else {
            this.$message({
              showClose: true,
              message: res,
              type: 'error'
            })
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
      // console.log(data)
    },
    // 进入空间
    initSpa (space) {
      console.log(space)
      var spaceId = 0
      var spaceDescripe = ''
      console.log(this.setContent)
      if (this.isZH) {
        // 个人
        spaceId = space.personSpaceId
        spaceDescripe = space.descript
      } else {
        spaceId = space.spaceId
        console.log(spaceId)
        spaceDescripe = space.descript
      }
      console.log(spaceId)
      // return
      localStorage.setItem('eleSpaceId', spaceId)
      // 保存企业编码
      localStorage.setItem('enterpriseCode', spaceDescripe)
      this.$axios.get(`/api/space/getuserpermissionbyspaceid?spaceId=${spaceId}`).then(res => {
        this.authoritySpace(spaceId)
        this.initSpace(JSON.parse(localStorage.getItem('spaceList')))
        // console.log()
        this.$router.push('/projectType')
      })

      // },
    },
    authoritySpace (id) {
      // localStorage.setItem('eleSpaceId', space.id)
      // if(!localStorage.getItem('eleSpaceId')){
      // 	return
      // }
      // var id = localStorage.getItem('eleSpaceId')
      this.$axios.get(`/api/space/getuserpermissionbyspaceid?spaceId=${id}`).then(res => {
        console.log(res)
        var roleList = res.permissions
        var actitons = {}
        for (var item of roleList) {
          actitons[item.permissionDescribe] = true
        }
        // console.log(actitons)
        this.initspaceAction(actitons)
      })
    },
    // 创建团队
    register (formName) {
      console.log(formName)
      var userName = this.registerUser.email
      var passWord = this.registerUser.pwd
      var code = this.registerUser.verificationCode
      this.$refs[formName].validate(valid => {
        if (valid) {
          console.log(this.registerUser)
          if (this.registerUser.pwd != this.registerUser.surePassword) {
            this.$message({
              type: 'warning',
              message: '密码和确认密码不一致'
            })
            return
          }
          this.$axios.get('/api/User/RegisterUser?userName=' + userName + '&password=' + passWord + '&code=' + code).then(
            res => {
              if (res.descript) {
                this.$message({
                  type: 'success',
                  message: '创建成功'
                })
                this.personalData.push(res)
                this.isRegister = false
              } else {
                this.$message({
                  type: 'error',
                  message: res
                })
              }
            })
        }
      })
    },
    sendCode () {
      const reg = /^([a-zA-Z0-9]+[-_\.]?)+@[a-zA-Z0-9]+\.[a-z]+$/
      const value = this.registerUser.email
      if (value == '' || value == undefined || value == null) {
        this.$message({
          type: 'warning',
          message: '请输入邮箱地址'
        })
        return
      } else {
        if (!reg.test(value)) {
          this.$message({
            type: 'error',
            message: '请输入正确的邮箱地址'
          })
          return
        }
      }
      this.countdown = 60
      var formDate = new FormData()
      formDate.append('recipientArry[]', this.registerUser.email)
      formDate.append('mailTitle', '注册')
      var _this = this
      var time = window.setInterval(function () {
        if (_this.countdown === 0) {
          window.clearInterval(time)
        } else {
          _this.countdown -= 1
        }
      }, 1000)
      this.$axios.post('/api/Auth/PostEmails', formDate).then(res => {
        this.$message({
          type: 'success',
          message: '验证码发送成功'
        })
      })
    },
    adduploadPicture (file) {
      addformData.append('FormFile', file.file)
    },
    addgetPicture (file, fileList) {
      this.addPictureUrl = file.url
    },
    // 判断addformData是否有该属性
    haveAttu (name, value) {
      if (addformData.get(name) == null) {
        addformData.append(name, value)
      } else {
        addformData.set(name, value)
      }
    },
    addCompany () {
      this.$refs.ruleFormS.validate(valid => {
        if (valid) {
          // this.haveAttu("Id",'12');
          this.haveAttu('Name', this.ruleFormSpace.Name)
          this.haveAttu('Descript', this.ruleFormSpace.Descript)
          if (this.ruleFormSpace.SpaceSize == '无限制') {
            this.haveAttu('SpaceSize', 1)
          } else {
            this.haveAttu('SpaceSize', this.ruleFormSpace.SpaceSize)
          }
          this.haveAttu('NumberAccount', this.ruleFormSpace.NumberAccount)
          this.haveAttu('ExpireTime', this.ruleFormSpace.ExpireTime)
          this.haveAttu('SpaceOwnerName', this.ruleFormSpace.SpaceOwnerName)
          this.haveAttu('Statu', 1)
          console.log(this.ruleFormSpace)
          // console.log(addformData.getAll())
          this.$axios.post('/api/space/addspace', addformData).then(res => {
            console.log(res)
            if (res.id) {
              var space = {}
              console.log(this.teamData)
              space.id = res.id
              space.coverPictureStr = res.headPortrait
              space.spaceName = this.ruleFormSpace.Name
              space.descript = this.ruleFormSpace.Descript
              space.spaceSize = this.ruleFormSpace.SpaceSize
              space.numberAccount = this.ruleFormSpace.NumberAccount
              space.numberAccountUsed = 1
              space.expireTime = this.ruleFormSpace.ExpireTime
              space.registerTime = this.getFullTime(0)
              space.ownUserName = this.ruleFormSpace.SpaceOwnerName
              space.projectTypeListDto = []
              space.lastDays = this.getLastDays(this.ruleFormSpace.ExpireTime)
              this.teamData.push(space)
              this.$message({
                type: 'success',
                message: '添加成功'
              })
              this.isCompany = false
            } else {
              this.$message({
                type: 'error',
                message: res
              })
            }
            //
          })
        }
      })
    },
    getFullTime (i) {
      let date = new Date() // 时间戳为10位需*1000，时间戳为13位的话不需乘1000
      let Y = date.getFullYear() + ''
      let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1)
      let D = (date.getDate() < 10 ? '0' + (date.getDate()) : date.getDate())
      let h = (date.getHours() < 10 ? '0' + (date.getHours()) : date.getHours())
      let m = (date.getMinutes() < 10 ? '0' + (date.getMinutes()) : date.getMinutes())
      let s = (date.getSeconds() < 10 ? '0' + (date.getSeconds()) : date.getSeconds())
      if (i == 1) {
        return Y + '-' + M + '-' + D
      } else {
        return Y + '-' + M + '-' + D + ' ' + h + ':' + m + ':' + s
      }
    },
    getLastDays (i) {
      let data = new Date(i)
      data = data.getTime()
      let data2 = new Date()
      data2 = data2.getTime()
      let data3 = (data - data2) / 86400000
      data3 = Math.floor(data3)
      return data3
    },
    addImg (data) {
      this.addImgData = data
      this.coverPictureStrImg = data.coverPictureStr
      console.log(data)
      this.isAddImg = true
    },
    addImgRequest (file) {
      imgformData.append('FormFile', file.file)
    },
    addimgPicture (file, fileList) {
      this.coverPictureStrImg = file.url
    },
    addImgSure () {
      // this.haveAttu("id",this.addImgData.personSpaceId);
      if (this.isZH) {
        imgformData.set('id', this.addImgData.personSpaceId)
      } else {
        imgformData.set('id', this.addImgData.spaceId)
      }
      imgformData.set('statu', 1)
      imgformData.set('CreatorId', localStorage.getItem('USER_ID'))
      imgformData.set('CoverPicture', this.coverPictureStrImg)
      this.$axios.post('/api/project/updatecoverpicture', imgformData).then(res => {
        console.log(res)
        if (res.id) {
          if (this.isZH) {
            let index = this.personalData.findIndex(item => {
              return item.personSpaceId == this.addImgData.personSpaceId
            })
            console.log(index)
            this.personalData[index].coverPictureStr = res.headPortrait
          } else {
            let index = this.teamData.findIndex(item => {
              return item.spaceId == this.teamData.spaceId
            })
            this.teamData[index].coverPictureStr = res.headPortrait
          }
          this.$message({
            type: 'success',
            message: '添加成功'
          })
          this.isAddImg = false
        } else {
          this.$message({
            type: 'error',
            message: res
          })
        }
      })
    },
    back () {
      this.$router.push('/space')
    },
    driveExcel () {
      let id1 = '#personalTable'
      let id2 = '#teamTable'
      if (this.isZH) {
        this.$refs.paginationPersonal.export_pagesize(1000, '#personalTable', '个人账号列表')
      } else {
        this.$refs.paginationTeam.export_pagesize(1000, '#teamTable', '公司账号列表')
      }
    },
    fixHomePage () {
      this.isHomePageInfo = true
    }
  }
}
</script>

<style scoped lang="less">
	.adminSpace {

		// .createTeam{
		// 	.el-button--primary{
		// 		background-color: #475065;
		// 	}
		// }
		.margin-left {
			margin-left: 10px;
		}

		.el-button--primary {
			background-color: #475065;
			border: none;
		}

		.admin-container {
			// background-color: #000000;
			// height: calc(100vh - 60px);
			// height: 877px;
			background-color: #e8e8e8;
			overflow-y: auto;
			padding: 15px 25px;
			box-sizing: border-box;

			.main-right {
				margin-right: 5px;
			}

			.content {
				width: 100%;
				height: 100%;

				// background-color: #000000;
				.content-head {
					display: flex;
					width: 100%;
					height: 80px;
					// background-color: #000000;
					justify-content: space-between;
					align-items: flex-end;

					.btn-left {
						position: relative;
						height: 30px;

						span {
							position: absolute;
							z-index: 999;
							display: inline-block;
							width: 200px;
							height: 30px;
							line-height: 30px;
							background-color: #fff;
							// margin-top: 10px;
							// box-sizing: border-box;
						}

						span:nth-child(1) {
							top: 0;
							left: 0;
						}

						span:nth-child(2) {
							top: 0;
							left: 202px;
						}

						.active {
							border: none;
							border: 1px solid #000000;
							border-bottom: none;
							background-color: #ececec;
						}

						.active2 {
							left: 200px !important;
						}
					}

					.btn-right {
						display: flex;
						height: 40px;
						margin-bottom: 20px;

						.el-button--primary {
							background: #475065;
							border: none;
						}
					}
				}

				.content-body {
					position: relative;

					.el-table {
						// position: absolute;
						z-index: 0;
						border: 1px solid #000000;
					}

					.handle-box {
						width: 70px;
						display: flex;
						justify-content: space-between;
					}
				}
			}
		}
	}
</style>
