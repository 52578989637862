<template>
	<div class="page-container">
		<div class="admin-homepageinfo-container">
			<p style="text-align: right;">
				<el-button type="primary" @click="back">返回</el-button>
			</p>
			<el-tabs style="height: 100%;">
				<el-tab-pane label="首页管理">
					<div class="homePageInfoContent">
						<el-form :model="homePageInfo" :rules="homePageInfoRules" ref="homePageForm" label-width="100px" class="demo-ruleForm">
							<el-form-item label="首页公告" prop="homePageMainContent">

								<el-input type="textarea" :rows="2" placeholder="请输入内容" v-model="homePageInfo.homePageMainContent"></el-input>
							</el-form-item>
							<p class="warningInfo">注：公告文字如需换行请输入  &lt;/br&gt;  换行字符，其他换行方式均不生效。</p>
							<el-form-item label="联系邮箱" prop="email">
								<el-input v-model="homePageInfo.email"></el-input>
							</el-form-item>
							<el-form-item label="手机号" prop="phone">
								<el-input v-model="homePageInfo.phone"></el-input>
							</el-form-item>
							<el-form-item>
								<p style="text-align: right;margin-top: 100px;" class="homePageBtn">
									<el-button type="primary" @click="submitPageInfo">保存</el-button>
									<!-- <el-button type="primary" @click="resetPageInfo(`homePageForm`)">重置</el-button> -->
								</p>
							</el-form-item>
						</el-form>
					</div>
				</el-tab-pane>
				<el-tab-pane label="下载管理">
					<div>
						<el-select style="width:200px;float:left;margin-bottom: 10px;" v-model="value" placeholder="请选择" @change="changeProjectType">
							<el-option
							v-for="item in ProductType"
							:key="item.value"
							:label="item.label"
							:value="item.value">
							</el-option>
						</el-select>
						<el-button style="float:right" type="primary" @click="isUploadProduct = true">上传</el-button>
					</div>
					 <el-table
						:data="tableData"
						stripe
						height="620"
						style="width: 100%;">
						<el-table-column
						prop="name"
						label="产品名称"
						>
						</el-table-column>
						<el-table-column
						label="产品类型"
						width="180">
						<template slot-scope="scope">
							<label v-if="scope.row.type==1">安装包</label>
							<!-- <label v-else-if="scope.row.type==2">教学视频</label>
							<label v-else-if="scope.row.type==3">操作手册</label>-->
							<label v-else>其他</label>
						</template>
						</el-table-column>
						<el-table-column
						prop="size_MB"
						label="大小"
						width="180">
						</el-table-column>
						<el-table-column
						prop="creationTimeStr"
						label="日期"
						width="180">
						</el-table-column>
						<el-table-column label="操作" width="180">
							<template slot-scope="scope">
								<div class="handle-box">
									<i class="el-icon-delete" style="cursor: pointer;" @click="deleteProduct(scope.row)"></i>
									<i class="el-icon-download" style="margin-left: 10px;cursor: pointer;" @click="downloadProduct(scope.row)"></i>
								</div>
							</template>
						</el-table-column>
					</el-table>
				</el-tab-pane>
				<el-dialog title="上传产品" :visible.sync="isUploadProduct" width="30%">
					<el-form  ref="form"  label-width="80px">
						<el-form-item label="产品类型" prop="type">
							<el-select v-model="value" placeholder="请选择">
								<el-option
								v-for="item in ProductType"
								:key="item.value"
								:label="item.label"
								:value="item.value">
								</el-option>
							</el-select>
						</el-form-item>
						<el-form-item prop="product">
							<el-upload
								class="upload-demo"
								drag
								action
								:http-request="upload_product"
								multiple>
								<i class="el-icon-upload"></i>
								<div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
							</el-upload>
						</el-form-item>
						<p style="text-align: right;">
							<el-button type="danger" @click="isUploadProduct = false">取消</el-button>
							<el-button type="primary" @click="UploadProduct()">确定</el-button>
						</p>
					</el-form>
				</el-dialog>
				<!-- <el-tab-pane label="角色管理">角色管理</el-tab-pane> -->
				<!-- <el-tab-pane label="定时任务补偿">定时任务补偿</el-tab-pane> -->
			</el-tabs>
		</div>
	</div>
</template>

<script>
var formData = new FormData()
export default {
  data () {
    var checkEmail = (rule, value, callback) => {
      const mailReg = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/
      if (!value) {
        return callback(new Error('邮箱不能为空'))
      }
      setTimeout(() => {
        if (mailReg.test(value)) {
          callback()
        } else {
          callback(new Error('请输入正确的邮箱格式'))
        }
      }, 100)
    }
    const checkPhone = (rule, value, callback) => {
      const phoneReg = /^1[3|4|5|7|8|9][0-9]{9}$/
      if (!value) {
        return callback(new Error('电话号码不能为空'))
      }
      setTimeout(() => {
        // Number.isInteger是es6验证数字是否为整数的方法,但是我实际用的时候输入的数字总是识别成字符串
        // 所以我就在前面加了一个+实现隐式转换
        if (!Number.isInteger(+value)) {
          callback(new Error('请输入数字值'))
        } else {
          if (phoneReg.test(value)) {
            callback()
          } else {
            callback(new Error('电话号码格式/长度不正确'))
          }
        }
      }, 100)
    }
    return {
      homePageInfo: {
        homePageMainContent: '',
        email: '',
        phone: ''
      },
      homePageInfoRules: {
        homePageMainContent: [{
          required: true,
          message: '请填写首页公告信息',
          trigger: 'blur'
        }],
        email: [{
          required: true,
          validator: checkEmail,
          trigger: 'blur'
        }],
        phone: [{
          required: true,
          validator: checkPhone,
          trigger: 'blur'
        }]
      },
      isUploadProduct: false,
      ProductType: [{
        value: '1',
        label: 'Revit安装包'
      },
      // {
      // value: '2',
      // label: '教学视频'
      // }, {
      // value: '3',
      // label: '操作手册'
      // },
      {
        value: '4',
        label: '其他'
      }
      ],
      value: '1',
      tableData_Product: [],
      tableData: []
    }
  },
  created () {
    this.init()
    this.getProducts()
  },
  methods: {
    back () {
      this.$parent.isHomePageInfo = false
    },
    init () {
      this.$axios.get(`/api/homepage/gethomepageinfo`).then(res => {
        this.homePageInfo = res
      })
    },
    getProducts () {
      this.$axios.get(`/api/product/getProducts`).then(res => {
        this.tableData_Product = res
        this.changeProjectType()
      })
    },
    submitPageInfo () {
      this.$refs.homePageForm.validate((valid) => {
        if (valid) {
          this.$axios.post(`/api/homepage/addorupdatehomepage`, this.homePageInfo).then(res => {
            this.$message({
              message: '修改成功',
              type: 'success'
            })
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    resetPageInfo (formName) {
      this.$refs[formName].resetFields()
    },

    changeProjectType () {
      console.log(this.value)
      this.tableData = this.tableData_Product.filter(v => {
        return v.type == this.value
      })
      console.log(this.tableData)
    },
    upload_product (file) {
      console.log(file.file)
      formData.append('formFiles', file.file)
    },
    UploadProduct () {
      formData.append('type', this.value)
      this.$axios.post(`/api/product/uploadProduct`, formData).then(res => {
        this.getProducts()
        this.isUploadProduct = false
        this.$message({
          message: '上传成功',
          type: 'success'
        })
        formData = new FormData()
      })
    },
    downloadProduct (row) {
      // location.href = row.serviceAttachmentAddress
      location.href = `/api/product/downproduct?filePath=${row.attachmentAddress}`
    },
    deleteProduct (row) {
      this.$axios.get(`/api/product/deleteProduct?deleteIds=${row.id}`).then(res => {
        this.getProducts()
        this.$message({
          message: '删除成功',
          type: 'success'
        })
      })
    }
  }
}
</script>

<style scoped lang="less">
	.btn-right {
		display: flex;
		height: 40px;
		margin-bottom: 20px;

		.el-button--primary {
			background: #475065;
			border: none;
		}
	}
	.page-container {
		padding: 30px 150px;
		width: 100%;
		// height: calc(100vh - 60px);
		height: 877px;
		box-sizing: border-box;

		/deep/.el-tabs__active-bar {
			background-color: #475065;
		}

		/deep/.el-tabs__item.is-active {
			color: #475065;
		}

		/deep/.el-tabs__item:hover {
			color: #475065;
		}

		.el-button--primary {
			background-color: #475065;
			border: none;
		}

		// background-color: #ffffff;
		.admin-homepageinfo-container {
			padding: 18px;
			background-color: #fff;
			box-sizing: border-box;
			height: 100%;

			.homePageInfoContent {
				padding: 60px 0 0 0;
				box-sizing: border-box;
			}
			.warningInfo{
				text-align: left;
				margin-bottom: 20px;
				margin-left: 100px;
				color: #F56C6C;
				font-size: 14px;
			}
		}
	}
</style>
